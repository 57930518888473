import React from "react";
import { Row, Col, Container } from "react-bootstrap";
import PageWrapper from "../components/PageWrapper";
import SEO from "../components/Layout/SEO";

import imgBanner from "../assets/image/inner/camelo-journey.jpg";
import imgC1 from "../assets/image/inner/camelo-black.svg";
import imgC2 from "../assets/image/inner/thinker.svg";
import imgC3 from "../assets/image/inner/soldier.svg";
import imgC4 from "../assets/image/inner/camel.svg";

const AboutPage = () => {
  return (
    <>
      <PageWrapper
        headerConfig={{
          align: "right",
          button: "account", // cta, account, null
        }}
      >
        <SEO
          title="About Camelo | The Journey of Team Camelo"
          description="Learn more about the Camelo team and our journey to help make shift work & employee scheduling easier for small businesses."
        ></SEO>
        <div className="inner-banner">
          <Container>
            <Row className="justify-content-center mt-md-6 pt-24 pt-lg-29">
              <Col lg="9" xl="8">
                <div className="px-md-12 text-center mb-11 mb-lg-14">
                  <h2 className="title gr-text-2 mb-9 mb-lg-12">Our Journey</h2>
                  <p className="gr-text-8 mb-0">
                    of a thousand miles begins with a single step
                  </p>
                </div>
              </Col>
              <Col xs="12">
                <div className="banner-fluid-image pt-lg-9">
                  <img
                    src={imgBanner}
                    className="w-100 rounded-10"
                    alt="The Camelo Journey - Photo by Carlos Leret"
                  />
                </div>
              </Col>
            </Row>
          </Container>
        </div>

        <div className="about-content pt-lg-28 pt-13 pb-24">
          <Container>
            <Row className=" align-items-center justify-content-center">
              <Col xs="10" lg="10" className="mb-7 mb-lg-0">
                <h2 className="gr-text-3 mb-0 pr-xl-16 text-center">
                  We are here to help <br className="d-none d-lg-block" />{" "}
                  customers get their success.
                </h2>
              </Col>
              <Col xs="10" lg="12">
                <div className="pt-12">
                  <p className="gr-text-8 mb-7 mb-lg-10 text-black">
                    At Camelo HQ, we are true believers in how important it is
                    to make work collaboration and goal achievement simpler for
                    small businesses. We spend our days building a product we
                    love:{" "}
                    <strong>
                      Camelo—the mobile app that assists business owners and
                      non-desk workers.
                    </strong>
                  </p>
                  <p className="gr-text-8 mb-0 text-black">
                    Camelo is committed to making shift work easier for you,
                    whether it's scheduling, tracking employee time and
                    attendance, or communicating with team members. We put
                    ourselves in your shoes, experience the challenges you've
                    been through, and offer products that help your business
                    thrive in the long term.
                  </p>
                </div>
              </Col>
            </Row>
          </Container>
        </div>

        <div className="values-content">
          <Container>
            <Row className="pt-14 pb-24 border-top">
              <Col xs="10" lg="5" className="mb-7 mb-lg-0">
                <div className="">
                  <h2 className="gr-text-3 mb-0">Our core values</h2>
                  <p className="gr-text-opacity gr-text-10 pt-4">
                    We integrate our core values into almost everything we do.
                  </p>
                </div>
              </Col>
              <Col xs="10" lg="7">
                <div className="">
                  <p className="gr-text-6 font-weight-bold text-black">
                    Accountability
                  </p>
                  <p className="gr-text-8 mb-7 mb-lg-10 text-black">
                    Our team members are committed to delivering high-quality
                    work outcomes with little supervision. We value the power of
                    self-discipline and keeping our word.
                  </p>
                  <p className="gr-text-6 font-weight-bold text-black">
                    Quality
                  </p>
                  <p className="gr-text-8 mb-0 text-black">
                    We love what we do and we care deeply about the quality that
                    we deliver. We're continuously learning and setting higher
                    standards for ourselves. We always aim to provide products
                    and services that bring true value to our users.
                  </p>
                </div>
              </Col>
            </Row>
          </Container>
        </div>

        <div className="about-content pb-24">
          <Container>
            <Row className="pt-14 border-top">
              <Col xs="10" lg="5" className="mb-7 mb-lg-0">
                <div className="">
                  <h2 className="gr-text-3 mb-0">How we work</h2>
                </div>
              </Col>
              <Col xs="10" lg="7">
                <div className="">
                  <p className="gr-text-6 font-weight-bold text-black">
                    Trust, instead of track
                  </p>
                  <p className="gr-text-8 mb-7 mb-lg-10 text-black">
                    We strive to build a high-trust company culture where
                    everyone works with an outcome-focused mindset. Each member
                    is responsible for their performance and work results, hence
                    delivering excellent outcomes without being micromanaged.
                  </p>
                  <p className="gr-text-6 font-weight-bold text-black">
                    Deliver products that align with our values
                  </p>
                  <p className="gr-text-8 mb-7 mb-lg-10 text-black">
                    We strive to integrate accountability and quality into our
                    products. We build Camelo—an app that helps business owners
                    spend less time on admin work and micromanaging employees.
                    And we're constantly working on the app to make it a better
                    place for any users.
                  </p>
                  <p className="gr-text-6 font-weight-bold text-black">
                    Care about team's growth, health, and wellbeing
                  </p>
                  <p className="gr-text-8 mb-0 text-black">
                    We appreciate and value the growth, health, and wellbeing of
                    each team member. Together, we build a safe and caring work
                    environment where we encourage open conversations, learning
                    opportunities, and work-life balance.
                  </p>
                </div>
              </Col>
            </Row>
          </Container>
        </div>

        <div className="content-section pt-14 pt-lg-27 pb-13 pb-lg-27 bg-default-9">
          <Container>
            <Row className="align-items-center justify-content-center">
              <Col sm="8" lg="5" xl="6" className="mb-9 mb-lg-0">
                <div className="content-grid-image-group d-flex  mx-sm-n3">
                  <div className="single-image d-flex flex-column px-3 px-sm-6">
                    <img
                      src={imgC1}
                      alt=""
                      data-aos="zoom-in"
                      data-aos-duration="500"
                      className="w-100 mb-6 mb-sm-9 rounded-10"
                    />

                    <img
                      src={imgC2}
                      alt=""
                      data-aos="zoom-in"
                      data-aos-duration="500"
                      data-aos-delay="800"
                      className="w-100 mb-6 mb-sm-9 rounded-10"
                    />
                  </div>
                  <div className="single-image d-flex flex-column pt-11 px-3 px-sm-6">
                    <img
                      src={imgC3}
                      alt=""
                      data-aos="zoom-in"
                      data-aos-duration="500"
                      data-aos-delay="400"
                      className="w-100 mb-6 mb-sm-9 rounded-10"
                    />

                    <img
                      src={imgC4}
                      alt=""
                      data-aos="zoom-in"
                      data-aos-duration="500"
                      data-aos-delay="1200"
                      className="w-100 rounded-10"
                    />
                  </div>
                </div>
              </Col>
              <Col
                sm="8"
                lg="6"
                xl="5"
                className="offset-lg-1 offset-lg-1 offset-xl-0"
              >
                <div className="content-text">
                  <h2 className="gr-text-3 mb-7 mb-lg-11">Made remotely 🌎</h2>
                  <p className="gr-text-8 text-black mb-7 mb-lg-10">
                    We're a remote team with talents coming from worldwide. Each
                    member comes with unique backgrounds, experience,
                    personalities, and skills. We're united by the same goal: to
                    experiment, create value, and thrive together. And we don't
                    forget to laugh at our own dry humour jokes.
                  </p>
                  <ul className="text-black">
                    <li className="gr-text-6 mb-0">Wilmington, US</li>
                    <li className="gr-text-6 mb-0">Saigon, VN</li>
                  </ul>
                  <p className="pt-8">
                    <p className="gr-text-6 ">Interested to join our team ?</p>
                    <a
                      href="mailto:hello@camelohq.com"
                      className="btn-link with-icon gr-text-blue gr-text-7 font-weight-bold mt-5"
                    >
                      Apply now{" "}
                      <i className="icon icon-tail-right font-weight-bold"></i>
                    </a>
                  </p>
                </div>
              </Col>
            </Row>
          </Container>
        </div>
      </PageWrapper>
    </>
  );
};
export default AboutPage;
